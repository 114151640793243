<template>
  <div class="py-3 px-4 mb-3">
    <div class="row align-items-center justify-content-between mb-3">
      <div class="col-6">
        <span
          class="fw-bold me-5"
          v-text="`NEC: ${results.patient_nec}`"></span>
        <span
          class="fw-bold me-5"
          v-text="`Paciente: ${results.patient_complete_name}`"></span>
        <span
          class="fw-bold"
          v-if="results.partner_complete_name"
          v-text="`Pareja: ${results.partner_complete_name}`"></span>
      </div>
      <div class="col-6 text-end">
        <span class="fw-bold me-5" v-text="results.doctor"></span>
        <span class="fw-bold" v-text="results.date"></span>
      </div>
    </div>
    <div class="row px-5">
      <div class="col-3">
        <div class="mb-3">
          <span class="text-primary">{{
            translateLaboratories(laboratory.laboratory_type)
          }}</span>
        </div>
        <div v-if="results.edited_1">
          <label class="text-dark">Primera Edición:</label>
          <p v-text="results.edited_1"></p>
        </div>
        <div v-if="results.edited_2">
          <label class="text-dark">Segunda Edición:</label>
          <p v-text="results.edited_2"></p>
        </div>
      </div>
      <div class="col-9">
        <div>
          <label class="text-primary">Observaciones:</label>
        </div>
        <div class="">
          <template>
            <p v-text="laboratory.observations"></p>
          </template>
        </div>
      </div>
    </div>
    <div class="row align-items-center justify-content-between mb-3">
      <div class="col-3">
        <div class="titles me-4">Resultados</div>
      </div>
      <div class="col-9 text-end">
        <span class="fw-bold" v-text="results.date_results"></span>
      </div>
    </div>
    <div class="p-relative">
      <div :class="[isEmptyResults && !ableEdit ? 'empty-table' : '']">
        <div
          class="
            row
            justify-content-between
            border border-bottom-0 border-dark
            py-3
          ">
          <div class="col-3 fw-bold">Análisis Macroscópico</div>
          <div class="col-3"></div>
          <div class="col-3 fw-bold"><span>V. Ref.</span></div>
          <div class="col-3"></div>
        </div>
        <div
          class="
            row
            justify-content-between
            border border-bottom-0 border-dark
            py-3
          ">
          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-7">
              <span class="my-3">Hr. de recolecta:</span>
              <span>Días de abstinencia:</span>
            </div>
            <div class="d-flex flex-column col-5 text-center">
              <template v-if="ableEdit">
                <div class="d-flex mb-3">
                  <date-picker
                    v-model="results.collection_time"
                    format="HH:mm"
                    value-type="format"
                    input-class="form-control inputsGeneralClass w-100 text-center"
                    type="time" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.abstinance"
                    @keypress="isNumber($event)"
                    @keyup="limitDecimals($event)"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
              </template>
              <template v-else>
                <span class="my-3" v-text="results.collection_time"></span>
                <span v-text="results.abstinance"></span>
              </template>
            </div>
          </div>
          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-4">
              <span class="my-3">Volumen: </span>
              <span>PH: </span>
            </div>
            <div class="d-flex flex-column col-4 text-center">
              <template v-if="ableEdit">
                <div class="mb-3">
                  <input
                    v-model="results.volume"
                    @change="
                      tmpSumation();
                      tmpnSumation();
                    "
                    @keypress="isNumber($event)"
                    @keyup="limitDecimals($event)"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.ph"
                    @keypress="isNumber($event)"
                    @keyup="limitDecimals($event)"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
              </template>
              <template v-else>
                <span class="my-3" v-text="results.volume"></span>
                <span v-text="results.ph"></span>
              </template>
            </div>
          </div>
          <div class="d-flex flex-column col-3 fw-bold">
            <span class="mt-3">Mayor a 1.5 ml.</span>
          </div>
          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-5">
              <span class="my-3">Aspecto: </span>
              <span>Homogeniza: </span>
            </div>
            <div class="d-flex flex-column col-6 text-center">
              <template v-if="ableEdit">
                <div class="mb-3">
                  <select
                    v-model="results.appearance"
                    class="
                      form-select
                      inputsGeneralClass
                      w-100
                      text-center
                      pe-5
                    "
                    type="number"
                    placeholder="0">
                    <option disabled selected>Seleccionar</option>
                    <option value="normal">Normal</option>
                    <option value="abnormal">Anormal</option>
                  </select>
                </div>
                <div class="mb-3">
                  <select
                    v-model="results.homogenizes"
                    class="form-select inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0">
                    <option :value="true">Si</option>
                    <option :value="false">No</option>
                  </select>
                </div>
              </template>
              <template v-else>
                <span
                  class="my-3"
                  v-text="translateAppearance(results.appearance)"></span>
                <span v-text="results.homogenizes ? 'Si' : 'No'"></span>
              </template>
            </div>
          </div>
        </div>
        <div
          class="
            row
            justify-content-between
            border border-bottom-0 border-dark
            py-3
          ">
          <div class="col-3 fw-bold">
            <span>Análisis Microscópico</span>
          </div>
          <div class="col-3 fw-bold text-center pe-4">
            <span>V. Ref.</span>
          </div>
          <div class="col-3 fw-bold"><span>Morfología</span></div>
          <div class="col-3 fw-bold text-center">
            <span>V. Ref.</span>
          </div>
        </div>
        <div
          class="
            row
            justify-content-between
            border border-bottom-0 border-dark
            py-3
          ">
          <div class="row col-4 fw-bold align-items-center">
            <div class="row flex-column col-7">
              <span class="my-3 text-white">´</span>
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Concentr.(mil/ml):</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >A (%):</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >B (%):</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >C (%):</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >D (%):</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >A+B (%):</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >A+B+C (%):</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Total Móv. Prog:</span
              >
              <!-- <span>Capacitación:</span> -->
              <span class="text-white">´</span>
            </div>
            <div class="d-flex flex-column col-5 text-center">
              <template v-if="ableEdit">
                <span class="my-3">Fresco</span>
                <div class="mb-3">
                  <input
                    v-model="results.concentrate"
                    @change="
                      tmpSumation();
                      tmpnSumation();
                    "
                    @keypress="isNumber($event)"
                    @keyup="limitDecimals($event)"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.a"
                    @change="
                      abSummation();
                      abcSummation();
                      tmpSumation();
                      tmpnSumation();
                    "
                    step="0.01"
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    min="0"
                    max="100"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.b"
                    @change="
                      abSummation();
                      abcSummation();
                      tmpSumation();
                      tmpnSumation();
                    "
                    step="0.01"
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.c"
                    @change="abcSummation"
                    step="0.01"
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.d"
                    step="0.01"
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.ab"
                    disabled
                    step="0.01"
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.abc"
                    disabled
                    step="0.01"
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.movement"
                    disabled
                    step="0.01"
                    @keypress="isNumber($event)"
                    @keyup="limitDecimals($event)"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <!-- <div>
                  <select
                    v-model="results.training"
                    class="
                      form-select
                      inputsGeneralClass
                      w-100
                      text-center
                      pe-5
                    "
                    type="number" placeholder="0">
                    <option disabled selected>Seleccionar</option>
                    <option value="only_wash">Solo Lavado</option>
                    <option value="sperm_swim_up">Sperm/Swim-up</option>
                    <option value="swim_up_sperm">Swim-up/Psperm</option>
                    <option value="swim_up">Swim-up</option>
                    <option value="pure_sperm">Pure sperm</option>
                  </select>
                </div> -->
                <span class="text-white mt-4">´</span>
              </template>
              <template v-else>
                <span class="my-3">Fresco</span>
                <span v-text="results.concentrate" class="mb-3"></span>
                <span v-text="results.a" class="mb-3"></span>
                <span v-text="results.b" class="mb-3"></span>
                <span v-text="results.c" class="mb-3"></span>
                <span v-text="results.d" class="mb-3"></span>
                <span v-text="results.ab" class="mb-3"></span>
                <span v-text="results.abc" class="mb-3"></span>
                <span v-text="results.movement" class="mb-3"></span>
                <!-- <span v-text="results.training"></span> -->
                <span class="text-white">´</span>
              </template>
            </div>
          </div>
          <div class="row col-2 fw-bold">
            <div class="d-flex flex-column col-12 text-center">
              <span class="my-3 text-white">´</span>
              <span class="mb-3">Mayor a 15 mil/ml</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3">Mayor a 32%</span>
              <span class="text-white">´</span>
            </div>
          </div>
          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-8">
              <span :class="ableEdit ? 'my-4' : 'my-3'" class="text-white"
                >´</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Normal:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Anormales:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Alt. Cabeza:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Alt. P. Media:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Alt. Cola:</span
              >
              <span class="mb-3 text-white">´</span>
              <span :class="ableEdit ? 'mb-2' : 'mb-3'" class="text-white"
                >´</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'">TMPN:</span>
              <!-- tmpn -->
              <span class="text-white">´</span>
            </div>
            <div class="d-flex flex-column col-4 text-center">
              <template v-if="ableEdit">
                <span class="my-3">Fresco</span>
                <div class="d-flex mb-3 align-items-center">
                  <input
                    v-model="results.normal"
                    @change="
                      tmpnSumation();
                      abnormalSumation();
                    "
                    max="100"
                    min="0"
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" /><span>%</span>
                </div>
                <div class="d-flex mb-3 align-items-center">
                  <input
                    v-model="results.abnormal"
                    disabled
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" /><span>%</span>
                </div>
                <div class="d-flex mb-3 align-items-center">
                  <input
                    v-model="results.head"
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" /><span>%</span>
                </div>
                <div class="d-flex mb-3 align-items-center">
                  <input
                    v-model="results.half"
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" /><span>%</span>
                </div>
                <div class="d-flex mb-3 align-items-center">
                  <input
                    v-model="results.line"
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" /><span>%</span>
                </div>
                <span class="mb-3 text-white">´</span>
                <span class="mb-3 text-white">´</span>
                <div class="d-flex mb-3 align-items-center">
                  <input
                    v-model="results.tmpn"
                    disabled
                    @keypress="isNumber($event)"
                    @keyup="
                      limitNumber($event);
                      limitDecimals($event);
                    "
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" /><span>%</span>
                </div>
                <span class="text-white">´</span>
              </template>
              <template v-else>
                <span class="my-3">Fresco</span>
                <span v-text="results.normal" class="mb-3"></span>
                <span v-text="results.abnormal" class="mb-3"></span>
                <span v-text="results.head" class="mb-3"></span>
                <span v-text="results.half" class="mb-3"></span>
                <span v-text="results.line" class="mb-3"></span>
                <span class="mb-3 text-white">´</span>
                <span class="mb-3 text-white">´</span>
                <span v-text="results.tmpn" class="mb-3"></span>
                <span class="text-white">´</span>
              </template>
            </div>
          </div>
          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-12 text-center">
              <span class="my-3 text-white">´</span>
              <span class="mb-3">Mayor a 4%</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="mb-3 text-white">´</span>
              <span class="text-white">´</span>
            </div>
          </div>
        </div>
        <div
          class="
            row
            justify-content-between
            border border-bottom-0 border-dark
            py-3
          ">
          <div class="col-3 fw-bold">Otros Hallazgos</div>
          <div class="col-3 fw-bold">
            <span class="text-center">V. Ref.</span>
          </div>
          <div class="col-3"></div>
          <div class="col-3"></div>
        </div>
        <div
          class="
            row
            justify-content-between
            border border-bottom-0 border-dark
            py-3
          ">
          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-6">
              <span :class="ableEdit ? 'marginBottomForm mt-2' : 'my-3'"
                >Formas Inm:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Leucocitos:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Bacteria:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Licuefacción:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Color:</span
              >
              <!-- <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >C. Insem.:</span
              > -->
              <!-- <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Cultivo:</span
              > -->
              <!-- <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Anticuerpos:</span
              > -->
              <!-- <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Plasma seminal:</span
              > -->
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Eritrocitos:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Celular redondas:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Detritus celulares:</span
              >
              <span>Trozos de gel:</span>
            </div>
            <div class="d-flex flex-column col-6 text-center">
              <template v-if="ableEdit">
                <div class="mb-3">
                  <input
                    v-model="results.forms"
                    @keypress="isNumber($event)"
                    @keyup="limitDecimals($event)"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.leukocytes"
                    @keypress="isNumber($event)"
                    @keyup="limitDecimals($event)"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.bacterium"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="text" />
                </div>
                <div class="mb-3">
                  <select
                    v-model="results.liquefaction"
                    class="form-select inputsGeneralClass w-100 text-center">
                    <option :value="true">Si</option>
                    <option :value="false">No</option>
                  </select>
                </div>
                <div class="mb-3">
                  <select
                    v-model="results.color"
                    class="form-select inputsGeneralClass w-100 text-center">
                    <option value="opalescent_gray">Gris opalescente</option>
                    <option value="translucent">Translúcido</option>
                    <option value="yellowish">Amarillento</option>
                    <option value="pearly">Aperlado</option>
                    <option value="reddish">Rojizo</option>
                  </select>
                </div>
                <!-- <div class="mb-3">
                  <input
                    v-model="results.cinsem"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="text" />
                </div> -->
                <!-- <div class="mb-3">
                  <select
                    v-model="results.crop"
                    class="form-select inputsGeneralClass w-100 text-center">
                    <option :value="true">Si</option>
                    <option :value="false">No</option>
                  </select>
                </div> -->
                <!-- <div class="mb-3">
                  <select
                    v-model="results.antibodies"
                    class="form-select inputsGeneralClass w-100 text-center">
                    <option value="ibt">IBT</option>
                    <option value="mar_test">MAR test</option>
                  </select>
                </div> -->
                <!-- <div class="mb-3">
                  <input
                    v-model="results.seminal_plasma"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="text" />
                </div> -->
                <div class="mb-3">
                  <input
                    v-model="results.erythrocytes"
                    @keypress="isNumber($event)"
                    @keyup="limitDecimals($event)"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <input
                    v-model="results.round_cells"
                    @keypress="isNumber($event)"
                    @keyup="limitDecimals($event)"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0" />
                </div>
                <div class="mb-3">
                  <select
                    v-model="results.cellular_debris"
                    class="form-select inputsGeneralClass w-100 text-center">
                    <option value="no">No</option>
                    <option value="scarce">Escasos</option>
                    <option value="moderate">Moderados</option>
                    <option value="abundant">Abundantes</option>
                  </select>
                </div>
                <div class="mb-3">
                  <select
                    v-model="results.gel_pieces"
                    class="
                      form-select
                      inputsGeneralClass
                      w-100
                      text-center
                      pe-5
                    ">
                    <option disabled>Seleccionar</option>
                    <option value="no">No</option>
                    <option value="+">+</option>
                    <option value="++">++</option>
                    <option value="+++">+++</option>
                  </select>
                </div>
              </template>
              <template v-else>
                <span v-text="results.forms" class="my-3"></span>
                <span v-text="results.leukocytes" class="mb-3"></span>
                <span v-text="results.bacterium" class="mb-3"></span>
                <span v-text="results.liquefaction" class="mb-3"></span>
                <span v-text="results.color" class="mb-3"></span>
                <!-- <span v-text="results.cinsem" class="mb-3"></span>
                <span v-text="results.crop" class="mb-3"></span>
                <span v-text="results.antibodies" class="mb-3"></span>
                <span v-text="results.seminal_plasma" class="mb-3"></span> -->
                <span v-text="results.erythrocytes" class="mb-3"></span>
                <span v-text="results.round_cells" class="mb-3"></span>
                <span v-text="results.cellular_debris" class="mb-3"></span>
                <span v-text="results.gel_pieces"></span>
              </template>
            </div>
          </div>
          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-10">
              <span
                :class="ableEdit ? 'marginBottomForm mt-2' : 'my-3'"
                class="text-white"
                >´</span
              >
              <span>Menor a 1 mil/ml</span>
            </div>
          </div>
          <div class="row col-4 fw-bold">
            <div class="d-flex flex-column col-6">
              <span :class="ableEdit ? 'marginBottomForm mt-2' : 'my-3'"
                >Aglutinación:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Viscosidad:</span
              >
              <span :class="ableEdit ? 'marginBottomForm' : 'mb-3'"
                >Agregación:</span
              >
            </div>
            <div class="d-flex flex-column col-5 text-center">
              <template v-if="ableEdit">
                <div class="mb-3">
                  <select
                    v-model="results.agglutination"
                    class="
                      form-select
                      inputsGeneralClass
                      w-100
                      text-center
                      pe-5
                    ">
                    <option disabled>Seleccionar</option>
                    <option value="no">No</option>
                    <option value="+">+</option>
                    <option value="++">++</option>
                    <option value="+++">+++</option>
                  </select>
                </div>
                <div class="mb-3">
                  <select
                    v-model="results.viscosity"
                    class="
                      form-select
                      inputsGeneralClass
                      w-100
                      text-center
                      pe-5
                    ">
                    <option disabled selected>Seleccionar</option>
                    <option value="normal">Normal</option>
                    <option value="+">+</option>
                    <option value="++">++</option>
                    <option value="+++">+++</option>
                  </select>
                </div>
                <div class="mb-3">
                  <select
                    v-model="results.aggregation"
                    class="
                      form-select
                      inputsGeneralClass
                      w-100
                      text-center
                      pe-5
                    ">
                    <option disabled>Seleccionar</option>
                    <option value="no">No</option>
                    <option value="+">+</option>
                    <option value="++">++</option>
                    <option value="+++">+++</option>
                  </select>
                </div>
              </template>
              <template v-else>
                <span v-text="results.agglutination" class="my-3"></span>
                <span v-text="results.viscosity" class="mb-3"></span>
                <span v-text="results.aggregation" class="mb-3"></span>
              </template>
            </div>
          </div>
          <div class="row col-2 fw-bold"></div>
        </div>
        <div
          class="
            row
            justify-content-between
            border border-bottom-0 border-dark
            py-3
          ">
          <div class="col-6 fw-bold">
            MIOXSYS (Potencial de oxido de reducción)
          </div>
          <div class="col-3"></div>
          <div class="col-3 fw-bold"></div>
          <div class="col-3"></div>
        </div>
        <div
          class="
            row
            justify-content-between
            border border-bottom-0 border-dark
            py-3
          ">
          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-6">
              <span class="my-3">Resultado:</span>
            </div>
            <div class="d-flex flex-column col-6 text-center">
              <template v-if="ableEdit">
                <div>
                  <input
                    v-model="results.oxide_reduction_potential"
                    class="form-control inputsGeneralClass w-100 text-center"
                    type="text" />
                </div>
              </template>
              <template v-else>
                <span
                  v-text="results.oxide_reduction_potential"
                  class="my-3"></span>
              </template>
            </div>
          </div>
          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-4">
              <span class="my-3">Tipo:</span>
            </div>
            <div class="d-flex flex-column col-6 text-center">
              <template v-if="ableEdit">
                <div>
                  <select
                    v-model="results.oxide_reduction_potential_type"
                    class="form-select inputsGeneralClass w-100 text-center"
                    type="number"
                    placeholder="0">
                    <option value="normal">Normal</option>
                    <option value="moderate_high">Moderadamente Alto</option>
                    <option value="extreme_high">Extremedamente Alto</option>
                  </select>
                </div>
              </template>
              <template v-else>
                <span
                  v-text="results.oxide_reduction_potential_type"
                  class="my-3"></span>
              </template>
            </div>
          </div>
          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-10">
              <span class="my-3 text-white">´</span>
            </div>
          </div>

          <div class="row col-3 fw-bold">
            <div class="d-flex flex-column col-10">
              <span class="mt-2 mb-3 text-white">´</span>
              <span class="text-white">´</span>
            </div>
          </div>
        </div>
        <div class="row justify-content-between border border-dark py-3">
          <div class="row col-6 fw-bold">
            <span class="col-3">Biólogo</span>
            <span
              class="col-6"
              v-if="hasEditedUsers(results.edited_1, results.edited_2)"
              >{{ hasEditedUsers(results.edited_1, results.edited_2) }}</span
            >
          </div>
          <div class="row col-6 fw-bold">
            <span class="col-3">Diagnóstico</span
            ><template v-if="ableEdit">
              <div class="col-6">
                <select
                  v-model="results.diagnosis"
                  class="form-select inputsGeneralClass w-100 text-center"
                  type="number"
                  placeholder="0">
                  <option disabled selected>Seleccionar</option>
                  <option value="azoo">Azoospermia</option>
                  <option value="norm">Normozoospermia</option>
                  <option value="aste">Astenozoospermia</option>
                  <option value="tera">Teratozoospermia</option>
                  <option value="olig">Oligospermia</option>
                  <option value="crip">Criptozoospermia</option>
                  <option value="aste_tera">Astenoteratozoospermia</option>
                  <option value="olig_aste">Oligoastenozoospermia</option>
                  <option value="olig_tera">Oligoteratozoospermia</option>
                  <option value="necr">Necrozoospermia</option>
                  <option value="olig_aste_tera">
                    Oligoastenoteratozoospermia
                  </option>
                </select>
              </div>
            </template>
            <template v-else>
              <span v-text="results.diagnosis" class="col-6"></span>
            </template>
          </div>
          <div class="row col-6 fw-bold">
            <span class="col-3 text-white">´</span>
            <span class="col-6 text-white">´</span>
          </div>
          <div class="row col-6 fw-bold mt-3">
            <span class="col-3">Tipo</span
            ><template v-if="ableEdit">
              <div class="col-6">
                <select
                  v-model="results.diagnosis_type"
                  class="form-select inputsGeneralClass w-100 text-center pe-5">
                  <option disabled selected>Seleccionar</option>
                  <option value="mild">Leve</option>
                  <option value="severe">Severa</option>
                  <option value="moderate">Moderada</option>
                  <option value="serious">Grave</option>
                </select>
              </div>
            </template>
            <template v-else>
              <span v-text="results.diagnosis_type" class="col-6"></span>
            </template>
          </div>
          <div class="row col-12 fw-bold mt-4">
            <span class="col-2">Observaciones:</span>
            <div class="col-10">
              <template v-if="ableEdit">
                <textarea
                  v-model="results.observations_results"
                  class="form-control inputsGeneralClass w-100"
                  rows="3"></textarea>
              </template>
              <template v-else>
                <p v-text="results.observations_results"></p>
              </template>
            </div>
          </div>
          <div class="mt-5">
            <p>
              Referencia: Diagnóstico de la muestra de semen de acuerdo con los
              parámetros del manual de laboratorio para la examinación y
              procesamiento de semen humano. Sexta edición OMS. 2021.
            </p>
          </div>
        </div>
      </div>
      <div v-if="isEmptyResults && !ableEdit" class="rotate text-center col-12">
        <span>Sin resultados</span>
      </div>
    </div>
    <div class="row justify-content-end mt-4">
      <div v-if="ableEdit" class="col-2">
        <button @click="saveLaboratory" class="btn btn-primary form-control">
          Guardar
        </button>
      </div>
      <div v-if="!isEmptyResults && getUserType !== 'control'" class="col-2">
        <button @click="savePDF" class="btn btn-primary form-control">
          Descargar
        </button>
      </div>
    </div>
    <ResultPDF
      :idPDF="idPDF"
      :laboratory="laboratory"
      :key="keyResultPDF"></ResultPDF>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import ResultPDF from "./ResultPDF.vue";

export default {
  name: "ResultsCard",
  components: {
    DatePicker,
    ResultPDF,
  },
  props: {
    ableEdit: {
      required: false,
      type: Boolean,
      default: false,
    },
    laboratory: {
      required: false,
      type: Object,
    },
  },
  mounted() {
    this.getresults();
  },
  data() {
    return {
      status: {},
      idPDF: null,
      keyResultPDF: 0,
      tmpCalculate: 0,
      results: {
        abstinance: null,
        volume: null,
        ph: null,
        appearance: "normal",
        homogenizes: true,
        concentrate: null,
        a: null,
        b: null,
        c: null,
        d: null,
        ab: null,
        abc: null,
        movement: null,
        training: "only_wash",
        normal: null,
        abnormal: 100,
        head: null,
        half: null,
        line: null,
        tmpn: null,
        forms: null,
        leukocytes: null,
        agglutination: "no",
        aggregation: "no",
        viscosity: "normal",
        diagnosis: "azoo",
        observations: "-",
        observations_results: "-",
        // New values
        collection_time: "00:00",
        liquefaction: false,
        color: "-",
        cinsem: "-",
        crop: false,
        bacterium: "-",
        diagnosis_type: "mild",
        antibodies: "ibt",
        seminal_plasma: "-",
        erythrocytes: null,
        round_cells: null,
        cellular_debris: "scarce",
        gel_pieces: "no",
        oxide_reduction_potential: "-",
        oxide_reduction_potential_type: "normal",
      },
    };
  },
  methods: {
    hasEditedUsers(edited_1, edited_2) {
      return edited_1 || edited_2;
    },
    savePDF() {
      this.keyResultPDF += 1;
      this.idPDF = this.keyResultPDF;
    },
    abnormalSumation() {
      this.results.abnormal = 100 - this.results.normal;
    },
    abSummation() {
      this.results.ab = parseFloat(this.results.a) + parseFloat(this.results.b);
    },
    abcSummation() {
      this.results.abc =
        parseFloat(this.results.a) +
        parseFloat(this.results.b) +
        parseFloat(this.results.c);
    },
    tmpSumation() {
      const ab = parseFloat(this.results.a) + parseFloat(this.results.b);
      this.tmpCalculate =
        this.results.volume * this.results.concentrate * parseFloat(`0.${ab}`);
      this.results.movement = this.tmpCalculate;
    },
    tmpnSumation() {
      this.results.tmpn =
        this.tmpCalculate * parseFloat(`0.0${this.results.normal}`);
    },
    getresults() {
      this.$api.patient.getPatientLaboratory(this.laboratory.id).then((res) => {
        this.results = res.data;
        this.results.appearance =
          this.results.appearance === "normal"
            ? this.results.appearance
            : "abnormal";
        this.abnormalSumation();
      });
    },
    saveLaboratory() {
      this.$api.patient
        .updateLaboratory(this.laboratory.id, this.results)
        .then(() => {
          this.$parent.$parent.$parent.getLaboratories();
          this.$bvModal.hide("resultsCard");
        });
    },
    translateLaboratories(translate) {
      const laboratories = {
        freezing: "Congelación",
        spermogram: "Espermograma",
        pending: "Pendiente",
        results: "Resultados",
        default: "",
      };
      return laboratories[translate] || laboratories.default;
    },
    translateAppearance(translate) {
      const appearance = {
        normal: "Normal",
        abnormal: "Anormal",
        default: "Anormal",
      };
      return appearance[translate] || appearance.default;
    },
    translateDiagnosisType(translate) {
      const diagnosisType = {
        mild: "Leve",
        severe: "Severa",
        moderate: "Moderada",
        serious: "Grave",
        default: "",
      };
      return diagnosisType[translate] || diagnosisType.default;
    },
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;

      if (
        (charCode !== 8 &&
          charCode !== 0 &&
          charCode < 48 &&
          charCode !== 46) ||
        charCode > 57
      ) {
        evt.preventDefault();
      }
    },
    limitNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      evt = evt || window.event;
      if (parseFloat(evt.target.value) >= 100) {
        // eslint-disable-next-line no-param-reassign
        evt.target.value = 100;
      }
    },
    limitDecimals(evt) {
      if (evt.target.value % 1 !== 0) {
        // eslint-disable-next-line no-param-reassign
        evt.target.value = parseFloat(parseFloat(evt.target.value).toFixed(2));
      }
    },
  },
  computed: {
    ...mapGetters(["getUserProfile", "getUserType"]),
    isEmptyResults() {
      return this.results.status !== "results";
    },
  },
};
</script>
