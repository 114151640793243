<template lang="html">
  <div>
    <VueHtml2pdf
      :show-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1200"
      filename="laboratorio"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="letter"
      pdf-orientation="portrait"
      pdf-content-width="820px"
      @beforeDownload="beforeDownload($event)"
      ref="resultPDF">
      <div
        class="d-flex justify-content-center pt-4 pb-3 mb-3"
        slot="pdf-content">
        <div class="col-11 text-black">
          <div class="d-flex">
            <div class="col-6">
              <div class="row align-items-center justify-content-start">
                <div class="col-12">
                  <span
                    ><span class="fw-bold">Solicitó: </span
                    >{{ results.doctor }}</span
                  >
                </div>
              </div>
              <div class="row align-items-center justify-content-start">
                <div class="col-12">
                  <span
                    ><span class="fw-bold">Fecha de obtención de muestra: </span
                    >{{ results.date }}</span
                  >
                </div>
              </div>
              <div class="row align-items-center justify-content-start">
                <div class="col-12">
                  <span
                    ><span class="fw-bold"
                      >Fecha de emisión de resultados: </span
                    >{{ results.date_results }}</span
                  >
                </div>
              </div>
              <div class="row align-items-center justify-content-start">
                <div class="col-12">
                  <span
                    ><span class="fw-bold">Nombre paciente: </span
                    >{{ results.patient_complete_name }}</span
                  >
                </div>
              </div>
              <div
                v-if="results.partner_complete_name"
                class="row align-items-center justify-content-start">
                <div class="col-12">
                  <span
                    ><span class="fw-bold">Nombre pareja: </span
                    >{{ results.partner_complete_name }}</span
                  >
                </div>
              </div>
              <div class="row col-12 justify-content-start">
                <div class="col-6 align-items-center justify-content-start">
                  <div class="col-12">
                    <span class="fw-bold">Petición:</span>
                  </div>
                  <div class="col-12">
                    <span
                      class="mb-2"
                      v-text="
                        translateLaboratories(laboratory.laboratory_type)
                      "></span>
                  </div>
                </div>
                <div class="col-6 align-items-center justify-content-start">
                  <div class="col-12">
                    <span class="fw-bold">NEC:</span>
                  </div>
                  <div class="col-12">
                    <span class="mb-2" v-text="results.patient_nec"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6"></div>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <h3 class="mb-0 pb-0">Análisis de semen</h3>
          </div>
          <hr class="m-0 p-0" />
          <div class="row align-items-center justify-content-start mb-4">
            <div class="row g-0 col-4">
              <div class="col-12">
                <span class="ms-2 fw-bold">Análisis Macroscópico</span>
              </div>
            </div>
            <div class="row col-2">
              <div class="col-12">
                <span class="text-white">'</span>
              </div>
            </div>
            <div class="row col-2">
              <div class="col-12 text-center">
                <span class="fw-bold">V.Ref</span>
              </div>
            </div>
            <div class="row col-2">
              <div class="col-12">
                <span>IC 95%</span>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start mb-4">
            <div class="row g-0 col-4">
              <div class="col-8">
                <span class="ms-2">Hora de recogida:</span>
              </div>
              <div class="col-4">
                <span class="fw-bold" v-text="results.collection_time"></span>
              </div>
              <div class="col-8">
                <span class="ms-2">Días de abstinencia:</span>
              </div>
              <div class="col-4">
                <span class="fw-bold" v-text="results.abstinance"></span>
              </div>
            </div>
            <div class="row col-2">
              <div class="col-8">
                <span>Volumen:</span>
              </div>
              <div class="col-4">
                <span class="fw-bold" v-text="results.volume"></span>
              </div>
              <div class="col-8">
                <span>pH:</span>
              </div>
              <div class="col-4">
                <span class="fw-bold" v-text="results.ph"></span>
              </div>
            </div>
            <div class="row col-2">
              <div class="col-12 text-center">
                <span class="fw-bold">≥ 1.4 ml</span>
              </div>
              <div class="col-12 text-white">'</div>
            </div>
            <div class="row col-2">
              <div class="col-12">
                <span>(1.3-1.5)</span>
              </div>
              <div class="col-12">
                <span class="text-white">'</span>
              </div>
            </div>
            <div class="row col-2">
              <div class="col-9">
                <span>Aspecto:</span>
              </div>
              <div class="col-3">
                <span
                  class="fw-bold"
                  v-text="translateAppearance(results.appearance)"></span>
              </div>
              <div class="col-9">
                <span>Homogeniza:</span>
              </div>
              <div class="col-3">
                <span
                  class="fw-bold"
                  v-text="results.homogenizes ? 'Si' : 'No'"></span>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start mb-2">
            <div class="row g-0 col-6">
              <div class="col-7">
                <span class="ms-2 fw-bold">Análisis Microscópico</span>
              </div>
              <div class="row col-5">
                <div class="col-6">
                  <span class="fw-bold">V.Ref</span>
                </div>
                <div class="col-6">
                  <span>IC 95%</span>
                </div>
              </div>
            </div>
            <div class="row col-6">
              <div class="col-7">
                <span>Morfología</span>
              </div>
              <div class="row col-5">
                <div class="col-6">
                  <span class="fw-bold">V.Ref</span>
                </div>
                <div class="col-6">
                  <span>IC 95%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="row g-0 col-6">
              <div class="d-flex col-8">
                <div class="col-7">
                  <span class="text-white">'</span>
                </div>
                <div class="col-5">
                  <span class="fw-bold">Fresco</span>
                </div>
              </div>
              <div class="row col-3">
                <div class="col-11">
                  <span class="text-white">'</span>
                </div>
              </div>
            </div>
            <div class="row col-6">
              <div class="d-flex col-9">
                <div class="col-7">
                  <span class="text-white">'</span>
                </div>
                <div class="col-5">
                  <span class="fw-bold">Fresco</span>
                </div>
              </div>
              <div class="row col-3">
                <div class="col-11">
                  <span class="text-white">'</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6">
              <div class="d-flex col-9">
                <div class="col-6">
                  <span class="ms-2">Concentración:</span>
                </div>
                <div class="d-flex col-6">
                  <div class="col-6">
                    <span>{{ results.concentrate }} mill/ml</span>
                  </div>
                  <div class="col-6">
                    <span style="font-size: 0.95rem" class="fw-bold"
                      >≥ 16 mill/ml</span
                    >
                  </div>
                </div>
              </div>
              <div class="row col-3">
                <div class="col-11 text-center">
                  <span class="fw-bold">(15-18)</span>
                </div>
              </div>
            </div>
            <div class="row col-6">
              <div class="d-flex col-7">
                <div class="col-10">
                  <span>Normales:</span>
                </div>
                <div class="col-2">
                  <span>{{ results.normal }}%</span>
                </div>
              </div>
              <div class="row col-5">
                <div class="col-6">
                  <span class="fw-bold">≥ 4%</span>
                </div>
                <div class="col-6">
                  <span class="fw-bold">(3.9-4.0)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6">
              <div class="d-flex col-9">
                <div class="col-6">
                  <span class="ms-2">Progresiva rápida (A):</span>
                </div>
                <div class="col-6">
                  <span>{{ results.a }}</span>
                </div>
              </div>
              <div class="row col-3">
                <div class="col-11 text-white">
                  <span>'</span>
                </div>
              </div>
            </div>
            <div class="row col-6">
              <div class="d-flex col-7">
                <div class="col-10">
                  <span>Anormales:</span>
                </div>
                <div class="col-2">
                  <span>{{ results.abnormal }}%</span>
                </div>
              </div>
              <div class="row col-5">
                <div class="col-6">
                  <span class="text-white"></span>
                </div>
                <div class="col-6">
                  <span class="text-white"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6">
              <div class="d-flex col-9">
                <div class="col-6">
                  <span class="ms-2">Progresiva lenta (B):</span>
                </div>
                <div class="col-6">
                  <span>{{ results.b }}</span>
                </div>
              </div>
              <div class="row col-3">
                <div class="col-11 text-white">
                  <span>'</span>
                </div>
              </div>
            </div>
            <div class="row col-6">
              <div class="d-flex col-7">
                <div class="col-10">
                  <span>Alt. en cabeza:</span>
                </div>
                <div class="col-2">
                  <span>{{ results.head }}%</span>
                </div>
              </div>
              <div class="row col-5">
                <div class="col-6">
                  <span class="text-white"></span>
                </div>
                <div class="col-6">
                  <span class="text-white"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6">
              <div class="d-flex col-9">
                <div class="col-6">
                  <span class="ms-2">No Progresiva (C):</span>
                </div>
                <div class="col-6">
                  <span>{{ results.c }}</span>
                </div>
              </div>
              <div class="row col-3">
                <div class="col-11 text-white">
                  <span>'</span>
                </div>
              </div>
            </div>
            <div class="row col-6">
              <div class="d-flex col-7">
                <div class="col-10">
                  <span>Alt. en pieza media:</span>
                </div>
                <div class="col-2">
                  <span>{{ results.half }}%</span>
                </div>
              </div>
              <div class="row col-5">
                <div class="col-6">
                  <span class="text-white"></span>
                </div>
                <div class="col-6">
                  <span class="text-white"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6">
              <div class="d-flex col-9">
                <div class="col-6">
                  <span class="ms-2">Inmóvil (D):</span>
                </div>
                <div class="col-6">
                  <span>{{ results.d }}</span>
                </div>
              </div>
              <div class="row col-3">
                <div class="col-11 text-white">
                  <span>'</span>
                </div>
              </div>
            </div>
            <div class="row col-6">
              <div class="d-flex col-7">
                <div class="col-10">
                  <span>Alt. en cola:</span>
                </div>
                <div class="col-2">
                  <span>{{ results.line }}%</span>
                </div>
              </div>
              <div class="row col-5">
                <div class="col-6">
                  <span class="text-white"></span>
                </div>
                <div class="col-6">
                  <span class="text-white"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6">
              <div class="d-flex col-9">
                <div class="col-6">
                  <span class="ms-2">Progresivos (A+ B):</span>
                </div>
                <div class="d-flex col-6">
                  <div class="col-6">
                    <span>{{ results.ab.toFixed(2) }}</span>
                  </div>
                  <div class="col-6">
                    <span style="font-size: 0.95rem" class="fw-bold"
                      >≥ 30%
                    </span>
                  </div>
                </div>
              </div>
              <div class="row col-3">
                <div class="col-11 text-center">
                  <span class="fw-bold">(29-31)</span>
                </div>
              </div>
            </div>
            <div class="row col-6"></div>
          </div>
          <div class="row align-items-center justify-content-start mb-3">
            <div class="row g-0 col-6">
              <div class="d-flex col-9">
                <div class="col-6">
                  <span class="ms-2">Total mov prog:</span>
                </div>
                <div class="col-6">
                  <span>{{ results.movement.toFixed(2) }}</span>
                </div>
              </div>
              <div class="row col-3">
                <div class="col-11 text-white">
                  <span>'</span>
                </div>
              </div>
            </div>
            <div class="row col-6">
              <div class="d-flex col-7">
                <div class="col-10">
                  <span>Total mov prog norm:</span>
                </div>
                <div class="col-2">
                  <span>{{ results.tmpn.toFixed(2) }}%</span>
                </div>
              </div>
              <div class="row col-5">
                <div class="col-6">
                  <span class="text-white"></span>
                </div>
                <div class="col-6">
                  <span class="text-white"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-start mb-3">
            <div class="row g-0 col-6 justify-content-between">
              <div class="col-6">
                <span class="ms-2 fw-bold">Otros Hallazgos</span>
              </div>
              <div class="col-6 text-center">
                <span class="fw-bold">V.Ref</span>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6 justify-content-between">
              <div class="d-flex col-6">
                <div class="col-6">
                  <span class="ms-2">Formas Inm:</span>
                </div>
                <div class="col-6">
                  <span class="fw-bold">{{ results.forms }}</span>
                </div>
              </div>
              <div class="row col-6">
                <div class="col-11 text-white">
                  <span>'</span>
                </div>
              </div>
            </div>
            <div class="row col-6">
              <div class="d-flex col-7">
                <div class="col-10">
                  <span>Aglutinación:</span>
                </div>
                <div class="col-2">
                  <span>{{
                    results.agglutination == "no" ? "No" : results.agglutination
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6 justify-content-between">
              <div class="d-flex col-6">
                <div class="col-6">
                  <span class="ms-2">Leucocitos:</span>
                </div>
                <div class="col-6">
                  <span class="fw-bold">{{
                    results.leukocytes.toFixed(2)
                  }}</span>
                </div>
              </div>
              <div class="col-6 text-center">
                <span style="font-size: 0.95rem" class="fw-bold"
                  >&lt; 1 mill/ml</span
                >
              </div>
            </div>
            <div class="row col-6">
              <div class="d-flex col-7">
                <div class="col-10">
                  <span>Viscocidad:</span>
                </div>
                <div class="col-2">
                  <span>{{ translateViscosity(results.viscosity) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6"></div>
            <div class="row col-6">
              <div class="d-flex col-7">
                <div class="col-10">
                  <span>Detritus celulares:</span>
                </div>
                <div class="col-2">
                  <span>{{
                    translateCellularDebris(results.cellular_debris)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6"></div>
            <div class="row col-6">
              <div class="d-flex col-7">
                <div class="col-10">
                  <span>Agregación:</span>
                </div>
                <div class="col-2">
                  <span>{{ translateAggregation(results.aggregation) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="validPotential"
            class="row align-items-center justify-content-start mb-3">
            <div class="row g-0 col-6 justify-content-between">
              <div class="d-flex col-6">
                <div class="col-6">
                  <span class="ms-2">MIOXSYS:</span>
                </div>
                <div class="col-6">
                  <span class="fw-bold">{{
                    results.oxide_reduction_potential
                  }}</span>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex">
                  <div class="col-3">
                    <span>Tipo:</span>
                  </div>
                  <div class="col-9">
                    <span>{{
                      translateResultType(
                        results.oxide_reduction_potential_type
                      )
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-6"></div>
          </div>
          <!-- <div class="row align-items-center justify-content-start mb-3">
              <div class="row g-0 col-6"></div>
              <div class="row col-6">
                <div class="d-flex col-7">
                  <div class="col-10">
                    <span class="fw-bold">Otros:</span>
                  </div>
                  <div class="col-2">
                    <span class="fw-bold">{{ results.other }}%</span>
                  </div>
                </div>
                <div class="row col-5">
                  <div class="col-6">
                    <span class="text-white"></span>
                  </div>
                  <div class="col-6">
                    <span class="text-white"></span>
                  </div>
                </div>
              </div>
            </div> -->
          <div class="row align-items-center justify-content-start">
            <div class="row g-0 col-6 justify-content-between">
              <div class="d-flex col-6">
                <div class="col-6">
                  <span class="ms-2 fw-bold">Diagnóstico:</span>
                </div>
                <div class="col-6">
                  <span class="fw-bold">{{
                    translateDiagnosis(results.diagnosis)
                  }}</span>
                </div>
              </div>
              <div class="row col-6"></div>
            </div>
            <div class="row col-6"></div>
          </div>
          <div class="row mb-3 mt-2">
            <div class="row g-0 col-11">
              <span style="text-align: justify" class="ms-2"
                >Referencia: Diagnóstico de la muestra de semen de acuerdo con
                los parámetros del manual de laboratorio para la examinación y
                procesamiento de semen humano. Sexta edición OMS, 2021.</span
              >
            </div>
          </div>
          <div class="row mb-5">
            <div class="row g-0 col-12">
              <div class="ms-2">
                <span class="fw-bold me-3">Observaciones: </span>
                <p>{{ results.observations_results }}</p>
              </div>
            </div>
          </div>
          <div class="mb-2 text-white">.</div>
          <div
            class="
              d-flex
              flex-column
              justify-content-center
              align-items-center
              mt-3
              pt-3
            ">
            <div class="border-bottom border-dark text-white">
              _________________________________________
            </div>
            <div v-if="hasEditedUsers(results.edited_1, results.edited_2)">
              Biól. {{ hasEditedUsers(results.edited_1, results.edited_2) }}
            </div>
          </div>
        </div>
      </div>
    </VueHtml2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { mapGetters } from "vuex";

export default {
  name: "ResultPDF",
  data() {
    return {
      status: {},
      results: {
        abstinance: 0,
        volume: 0,
        ph: 0,
        appearance: "normal",
        homogenizes: true,
        concentrate: 0,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        ab: 0,
        abc: 0,
        movement: 0,
        training: "only_wash",
        normal: 0,
        abnormal: 100,
        head: 0,
        half: 0,
        line: 0,
        tmpn: 0,
        forms: 0,
        leukocytes: 0,
        agglutination: false,
        viscosity: "normal",
        diagnosis: "azoo",
        observations: "-",
        observations_results: "-",
        collection_time: "00:00",
        liquefaction: false,
        color: "-",
        cinsem: "-",
        crop: false,
        bacterium: "-",
        diagnosis_type: "-",
        antibodies: "-",
        seminal_plasma: "-",
        erythrocytes: 0,
        round_cells: 0,
        cellular_debris: "-",
        gel_pieces: false,
        oxide_reduction_potential: "-",
        oxide_reduction_potential_type: "normal",
      },
    };
  },
  props: {
    laboratory: {
      required: false,
      type: Object || undefined,
    },
    idPDF: {
      type: Number,
      required: false,
    },
  },
  components: {
    VueHtml2pdf,
  },
  watch: {
    idPDF() {
      this.getresults();
    },
  },
  mounted() {
    this.getresults();
  },
  updated() {
    if (this.idPDF) {
      this.$refs.resultPDF.generatePdf();
    }
  },
  computed: {
    ...mapGetters(["getUserProfile"]),
    isEmptyResults() {
      return this.results.status !== "results";
    },
    validPotential() {
      return (
        this.results.oxide_reduction_potential !== "-" &&
        this.results.oxide_reduction_potential !== "" &&
        this.results.oxide_reduction_potential !== null
      );
    },
  },
  methods: {
    hasEditedUsers(edited_1, edited_2) {
      return edited_1 || edited_2;
    },
    getresults() {
      this.$api.patient.getPatientLaboratory(this.laboratory.id).then((res) => {
        this.results = res.data;
      });
    },
    translateLaboratories(translate) {
      const laboratories = {
        freezing: "Congelación",
        spermogram: "Espermograma",
        pending: "Pendiente",
        results: "Resultados",
        default: "",
      };
      return laboratories[translate] || laboratories.default;
    },
    translateDiagnosisType(translate) {
      const diagnosisType = {
        mild: "Leve",
        severe: "Severa",
        moderate: "Moderada",
        serious: "Grave",
        default: "",
      };
      return diagnosisType[translate] || diagnosisType.default;
    },
    translateDiagnosis(translate) {
      const diagnosis = {
        azoo: "Azoospermia",
        norm: "Normozoospermia",
        aste: "Astenozoospermia",
        tera: "Teratozoospermia",
        olig: "Oligospermia",
        crip: "Criptozoospermia",
        aste_tera: "Astenoteratozoospermia",
        olig_aste: "Oligoastenozoospermia",
        olig_tera: "Oligoteratozoospermia",
        necr: "Necrozoospermia",
        olig_aste_tera: "Oligoastenoteratozoospermia",
        default: "",
      };
      return diagnosis[translate] || diagnosis.default;
    },
    translateResultType(translate) {
      const resultType = {
        normal: "Normal",
        moderate_high: "Moderadamente Alto",
        extreme_high: "Extremedamente Alto",
        default: "",
      };
      return resultType[translate] || resultType.default;
    },
    translateCellularDebris(translate) {
      const celularDebris = {
        scarce: "Escasos",
        moderate: "Moderados",
        abundant: "Abundantes",
        no: "No",
        default: "",
      };
      return celularDebris[translate] || celularDebris.default;
    },
    translateAggregation(translate) {
      const aggregation = {
        "no": "No",
        "+": "+",
        "++": "++",
        "+++": "+++",
        "default": "",
      };
      return aggregation[translate] || aggregation.default;
    },
    translateAntibodies(translate) {
      const antibodies = {
        ibt: "IBT",
        mar_test: "MAR test",
        default: "",
      };
      return antibodies[translate] || antibodies.default;
    },
    translateAppearance(translate) {
      const appearance = {
        normal: "Normal",
        abnormal: "Anormal",
        default: "Anormal",
      };
      return appearance[translate] || appearance.default;
    },
    translateViscosity(translate) {
      const viscosity = {
        "normal": "Normal",
        "+": "+",
        "++": "++",
        "+++": "+++",
        "default": "",
      };
      return viscosity[translate] || viscosity.default;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(1);
          }
        });
    },
  },
};
</script>
